import * as THREE from "three";
import { Mesh } from "three";
import { OBB } from 'three/examples/jsm/math/OBB';
import { ZPosition } from 'core/enums/ZPosition';
import IsProduction from "core/utils/IsProduction";
import { Position, Size } from "./Base";

export class CollisionPlane {
  mesh: Mesh;

  visibilityMesh?: Mesh;

  private yOffset: number;

  constructor(collisionPlaneDto: CollisionPlaneDto) {
    collisionPlaneDto.isInteractable = collisionPlaneDto.isInteractable ?? false;
    collisionPlaneDto.yOffset = collisionPlaneDto.yOffset ?? 0;
    collisionPlaneDto.color = collisionPlaneDto.color ?? 'red';

    this.yOffset = collisionPlaneDto.yOffset;

    const geometry = new THREE.PlaneGeometry(collisionPlaneDto.width, collisionPlaneDto.height);

    geometry.computeBoundingBox();

    this.mesh = new Mesh(geometry, new THREE.MeshBasicMaterial({ transparent: true, opacity: 0 }));
    this.mesh.position.set(collisionPlaneDto.x, collisionPlaneDto.y + collisionPlaneDto.yOffset, ZPosition.Player);
    this.mesh.geometry.userData.obb = new OBB().fromBox3(this.mesh.geometry.boundingBox as THREE.Box3);
    this.mesh.userData.obb = new OBB();
    this.mesh.userData.isInteractable = collisionPlaneDto.isInteractable;

    if (!IsProduction()) {
      const material = new THREE.MeshBasicMaterial({ color: collisionPlaneDto.color, opacity: .2, transparent: true });
      this.visibilityMesh = new Mesh(geometry, material);

      this.visibilityMesh.position.set(collisionPlaneDto.x, collisionPlaneDto.y + collisionPlaneDto.yOffset, ZPosition.CollisionLayer);
    }
  }

  moveX(x: number): void {
    this.mesh.position.setX(x);

    if (!IsProduction() && this.visibilityMesh) {
      this.visibilityMesh.position.setX(x);
    }
  }

  moveY(y: number): void {
    this.mesh.position.setY(y + this.yOffset);

    if (!IsProduction() && this.visibilityMesh) {
      this.visibilityMesh.position.setY(y + this.yOffset);
    }
  }
}

export interface CollisionPlaneDto extends Position, Size {
  isInteractable?: boolean;
  yOffset?: number;
  color?: string;
}
