import { GUI } from "dat.gui";
import * as THREE from "three";
import Stats from "three/examples/jsm/libs/stats.module";
import InitializeScene from "./Scene/InitializeScene";
import { Player } from "./Core/Player";
import { Menu } from "./Core/Menu";
import { Scene, PerspectiveCamera, WebGLRenderer, Audio } from "three";
import IsProduction from "core/utils/IsProduction";
import InitializeNpcs from "./Scene/InitializeNpcs";
import InitializeObjects from "./Scene/InitializeObjects";
import InitializeCollisions from "./Scene/InitializeCollisions";
import { Subject } from "rxjs";

export class GameRenderer {
  scene: Scene;
  camera: PerspectiveCamera;
  renderer: WebGLRenderer;
  gui?: GUI;
  playerCharacter: Player;
  audio: Audio;
  menu: Menu;

  unsubscribeNotifier$ = new Subject<void>();

  constructor() {
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(90, window.innerWidth / window.innerHeight, 0.1, 1000);

    this.menu = new Menu(this.scene);
    this.playerCharacter = new Player(this.camera, this.menu, this.unsubscribeNotifier$);

    this.camera.position.set(this.playerCharacter.sprite.position.x, this.playerCharacter.sprite.position.y, 8);
    this.camera.lookAt(this.playerCharacter.sprite.position);

    const listener = new THREE.AudioListener();
    this.audio = new THREE.Audio(listener);

    this.camera.add(listener);
    const audioLoader = new THREE.AudioLoader();
    audioLoader.load('./assets/background-music.mp3', buffer => {
      this.audio.setBuffer(buffer);
      this.audio.setLoop(true);
      this.audio.setVolume(.1);
      this.audio.play();
    });

    this.renderer = new THREE.WebGLRenderer();
  }

  initializeGame(mount: HTMLElement): void {
    // Scene
    InitializeScene(this.scene);
    const objectCollisions = InitializeObjects(this.scene, this.playerCharacter.playerYIndex$, this.unsubscribeNotifier$);
    const npcCollisions = InitializeNpcs(this.scene, this.unsubscribeNotifier$);
    this.playerCharacter.collisions = InitializeCollisions(this.scene, npcCollisions, objectCollisions);

    // Character
    this.scene.add(this.playerCharacter.sprite);
    this.scene.add(this.playerCharacter.collisionPlane.mesh);
    this.scene.add(this.playerCharacter.interactionRange.mesh);

    if (!IsProduction() && this.playerCharacter.collisionPlane.visibilityMesh && this.playerCharacter.interactionRange.visibilityMesh) {
      this.scene.add(this.playerCharacter.collisionPlane.visibilityMesh);
      this.scene.add(this.playerCharacter.interactionRange.visibilityMesh);
    }

    // Development Tools
    let stats: Stats;
    if (!IsProduction()) {
      // Grid Helper
      var yGridHelper = new THREE.GridHelper(100, 100);
      yGridHelper.position.set(0, 50, 0.01);
      yGridHelper.rotateX(Math.PI / 2)
      this.scene.add(yGridHelper);

      // GUI
      this.gui = new GUI();
      stats = Stats();
      mount.appendChild(stats.dom);

      // GUI Controls
      const cameraFolder = this.gui.addFolder('Camera');
      cameraFolder.add(this.camera.position, 'x', -50, 50, 1);
      cameraFolder.add(this.camera.position, 'y', 0, 100, 1);
      cameraFolder.add(this.camera.position, 'z', 0, 50, 1);
      cameraFolder.open();

      const characterHelperFolder = this.gui.addFolder('Character');
      characterHelperFolder.add(this.playerCharacter, 'speed', .08, .36, .04);
      characterHelperFolder.open();

      const gridHelperFolder = this.gui.addFolder('GridHelper');
      gridHelperFolder.add(yGridHelper, 'visible');
      gridHelperFolder.open();
    }

    // Renderer
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    window.addEventListener('resize', this.onWindowResize, false);

    const animate = () => {
      requestAnimationFrame(animate);
      this.reRender();

      if (!IsProduction()) stats.update();
    };

    mount.appendChild(this.renderer.domElement);
    animate();
  }

  reRender(): void {
    this.renderer.render(this.scene, this.camera);
  }

  private onWindowResize = () => {
    this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    this.reRender();
  }
}
