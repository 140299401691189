import { useEffect, useState } from 'react';
import './GamePortfolio.scss';
import ActionItems from './ActionItems/ActionItems';
import { GameRenderer } from './Game/GameRenderer';

const GamePortfolio = (props: { onStaticPortfolioButtonClick: () => void }) => {
  var mount: HTMLElement;
  const [gameRenderer] = useState(new GameRenderer());

  useEffect(() => {
    gameRenderer.initializeGame(mount);

    return () => {
      gameRenderer.gui?.destroy();
      gameRenderer.audio.stop();

      gameRenderer.unsubscribeNotifier$.next();
      gameRenderer.unsubscribeNotifier$.complete();
    }
  });

  return (
    <div className="game-portfolio">
      <div ref={ref => (mount = ref as HTMLElement)} />
      <ActionItems onStaticPortfolioButtonClick={props.onStaticPortfolioButtonClick}></ActionItems>
    </div>
  )
}

export default GamePortfolio;