import { useState } from 'react';
import './App.scss';
import GamePortfolio from './components/GamePortfolio/GamePortfolio';
import StandardPortfolio from './components/StandardPortfolio/StandardPortfolio';

function App() {
  const [showGamePortfolio, updateShowGamePortfolio] = useState(true);

  const toggleShowGamePortfolio = () => {
    updateShowGamePortfolio((prevState: any) => {
      return !prevState;
    });
  }

  return (
    <div className="app">
      {
        showGamePortfolio
          ? <GamePortfolio onStaticPortfolioButtonClick={toggleShowGamePortfolio} />
          : <StandardPortfolio onGamePortfolioButtonClick={toggleShowGamePortfolio} />
      }
    </div>
  );
}

export default App;
