import { CharacterAnimations } from "core/constants/CharacterAnimations";
import { Direction } from "core/enums/Direction";
import { NpcType } from "core/enums/NpcType";
import { ZPosition } from "core/enums/ZPosition";
import { Subject } from "rxjs";
import * as THREE from "three";
import { Sprite } from "three";
import { CollisionPlane } from "./CollisionPlane";
import { Position } from "./Base";

export class Npc {
  sprite: Sprite;
  collision: CollisionPlane;

  constructor(npcDto: NpcDto, unsubscribeNotifier$: Subject<void>) {
    npcDto.direction = npcDto.direction ?? Direction.Down;
    npcDto.isInteractable = npcDto.isInteractable ?? false;
    npcDto.zPosition = npcDto.zPosition ?? ZPosition.Npc;

    const texture = new THREE.TextureLoader().load(`./assets/characters/npc/${npcDto.npcType}.png`);
    texture.repeat.set(1 / CharacterAnimations.MovementIndexes, 1);
    texture.magFilter = THREE.NearestFilter;

    this.sprite = new THREE.Sprite();
    this.sprite.scale.set(1, 2, 1);
    this.sprite.position.set(npcDto.x, npcDto.y, npcDto.zPosition);
    this.sprite.material.map = texture;

    this.collision = new CollisionPlane({ x: npcDto.x, y: npcDto.y, width: 1, height: .5, isInteractable: npcDto.isInteractable, yOffset: -.75 });

    let tileIndexes: number[];
    switch (npcDto.direction) {
      case Direction.Up:
        tileIndexes = CharacterAnimations.IdlingCycles.Up;
        break;
      case Direction.Down:
        tileIndexes = CharacterAnimations.IdlingCycles.Down;
        break;
      case Direction.Left:
        tileIndexes = CharacterAnimations.IdlingCycles.Left;
        break;
      case Direction.Right:
        tileIndexes = CharacterAnimations.IdlingCycles.Right;
        break;
    }

    var counter = 0;
    const idleInterval = setInterval(() => {
      texture.offset.setX(tileIndexes[counter] / CharacterAnimations.MovementIndexes);

      ++counter;
      if (counter >= tileIndexes.length) {
        counter = 0;
      }
    }, Math.floor(Math.random() * (300 - 250)) + 250);

    unsubscribeNotifier$.subscribe(() => {
      clearInterval(idleInterval);
    });
  }
}

export interface NpcDto extends Position {
  npcType: NpcType;
  isInteractable?: boolean;
  direction?: Direction;
  zPosition?: ZPosition;
}
