import './StandardPortfolio.scss';

const StandardPortfolio = (props: { onGamePortfolioButtonClick: () => void }) => {
  const gamePortfolioButtonClickHandler = () => {
    props.onGamePortfolioButtonClick();
  }

  return (
    <div className="standard-portfolio">
      Standard Portfolio
      <button onClick={gamePortfolioButtonClickHandler}>Go to game portfolio</button>
    </div>
  );
}

export default StandardPortfolio;