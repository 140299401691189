import { faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ActionItems.scss";

const ActionItems = (props: { onStaticPortfolioButtonClick: () => void }) => {

  var staticPortfolioButtonClickHandler = () => {
    props.onStaticPortfolioButtonClick();
  }

  function instagramInButtonClickHandler() {
    window.open('https://www.instagram.com/jkang027/', '_blank', 'noopener,noreferrer');
  }

  function linkedInButtonClickHandler() {
    window.open('https://www.linkedin.com/in/jkang027/', '_blank', 'noopener,noreferrer');
  }

  return (
    <div className="action-items">
      <button className="personal-button instagram" onClick={instagramInButtonClickHandler}>
        <div className="white-cover" />
        <div className="color-background" />
        <FontAwesomeIcon icon={faInstagram} size="2x" />
      </button>
      <button className="personal-button linkedin" onClick={linkedInButtonClickHandler}>
        <FontAwesomeIcon icon={faLinkedin} size="2x" />
      </button>
      <button className="website-portfolio-button" onClick={staticPortfolioButtonClickHandler}>
        Static <br /> Portfolio
      </button>
    </div>
  );
}

export default ActionItems;