import { Scene } from 'three';
import { Npc } from 'components/GamePortfolio/Game/Core/Npc';
import { NpcType } from 'core/enums/NpcType';
import { Subject } from 'rxjs';
import { CollisionPlane } from '../Core/CollisionPlane';

const InitializeNpcs = (scene: Scene, unsubscribeNotifier$: Subject<void>): CollisionPlane[] => {
  const npcs: Npc[] = [];
  const collisions: CollisionPlane[] = [];

  npcs.push(
    new Npc({ npcType: NpcType.Butler, x: 0, y: 11.25, isInteractable: true }, unsubscribeNotifier$)
  );

  npcs.forEach(npc => {
    scene.add(npc.sprite);
    collisions.push(npc.collision);
  });

  return collisions;
}

export default InitializeNpcs;
