import * as THREE from "three";
import { Mesh, PerspectiveCamera, PlaneGeometry, Sprite } from "three";
import { Direction } from "core/enums/Direction";
import { Speed } from "core/enums/Speed";
import { CharacterAnimations } from "../../../../core/constants/CharacterAnimations";
import { ZPosition } from "core/enums/ZPosition";
import { Subject } from "rxjs";
import { CollisionPlane } from "./CollisionPlane";
import { Menu } from "./Menu";

export class Player {
  sprite: Sprite;
  collisionPlane: CollisionPlane;
  interactionRange: CollisionPlane;

  playerYIndex$ = new Subject<number>();

  camera: PerspectiveCamera;
  menu: Menu;
  collisions: CollisionPlane[] = [];

  private isMoving = false;
  private isSprintToggled = false;
  private isMenuOpen = false;

  private direction = Direction.Up;
  private speed = Speed.Walk;

  private idlingTexture = new THREE.TextureLoader().load('./assets/characters/player/idling.png');
  private runningTexture = new THREE.TextureLoader().load('./assets/characters/player/running.png');
  private readingTexture = new THREE.TextureLoader().load('./assets/characters/player/reading.png');

  private idleTimer?: NodeJS.Timer;
  private idleDuration = 0;

  private movementInterval?: NodeJS.Timer;
  private animationInterval!: NodeJS.Timer;
  private currentRunningAnimationIndex = 0;

  private keyDownMap = new Map<Direction, boolean>();
  private keyDirectionMap = new Map<string, Direction>([
    ['ArrowUp', Direction.Up],
    ['W', Direction.Up],
    ['w', Direction.Up],
    ['ArrowDown', Direction.Down],
    ['S', Direction.Down],
    ['s', Direction.Down],
    ['ArrowLeft', Direction.Left],
    ['A', Direction.Left],
    ['a', Direction.Left],
    ['ArrowRight', Direction.Right],
    ['D', Direction.Right],
    ['d', Direction.Right],
  ]);

  constructor(camera: PerspectiveCamera, menu: Menu, unsubscribeNotifier$: Subject<void>) {
    this.camera = camera;
    this.menu = menu;

    this.idlingTexture.repeat.set(1 / CharacterAnimations.MovementIndexes, 1);
    this.idlingTexture.magFilter = THREE.NearestFilter;

    this.runningTexture.repeat.set(1 / CharacterAnimations.MovementIndexes, 1);
    this.runningTexture.magFilter = THREE.NearestFilter;

    this.readingTexture.repeat.set(1 / CharacterAnimations.ReadingIndexes, 1);
    this.readingTexture.magFilter = THREE.NearestFilter;

    this.sprite = new THREE.Sprite();
    this.sprite.geometry.computeBoundingBox();
    this.sprite.scale.set(1, 2, 1);
    this.sprite.position.set(0, 2.5, ZPosition.Player);

    this.collisionPlane = new CollisionPlane({ x: this.sprite.position.x, y: this.sprite.position.y, width: 1, height: .5, yOffset: -.75 });
    this.interactionRange = new CollisionPlane({ x: this.sprite.position.x, y: this.sprite.position.y, width: 3, height: 3.5, yOffset: -.75, color: 'blue' });

    this.setIdleAnimation();
    this.setCharacterMovementEventListeners();
    this.toggleMenu();

    unsubscribeNotifier$.subscribe(() => {
      clearInterval(this.idleTimer);
      clearInterval(this.movementInterval);
      clearInterval(this.animationInterval);
      this.playerYIndex$.complete();
    });
  }

  private setCharacterMovementEventListeners(): void {
    document.addEventListener('keydown', (event: KeyboardEvent) => {
      var direction = this.keyDirectionMap.get(event.key);
      if (direction && !this.isMenuOpen) {
        this.changeDirection(direction);
      }
    });

    document.addEventListener('keyup', (event: KeyboardEvent) => {
      var direction = this.keyDirectionMap.get(event.key);
      if (direction) {
        this.keyDownMap.set(direction, false);

        var oldDirection = Array.from(this.keyDownMap.entries()).find((entry) => entry[1]);
        if (oldDirection && oldDirection[1]) {
          this.changeDirection(oldDirection[0]);
        } else {
          this.isMoving = false;
          this.setIdleAnimation();
        }
      }

      if (event.key === "Shift") {
        this.isSprintToggled = !this.isSprintToggled;
        this.speed = this.isSprintToggled ? Speed.Run : Speed.Walk;

        if (this.isMoving) {
          this.setRunAnimation(false);
        }
      };

      if (event.key === "Enter") this.toggleMenu();
    });
  }

  private toggleMenu(): void {
    if (this.isMenuOpen) {
      this.menu.close();
    } else {
      this.isMoving = false;
      this.setIdleAnimation();
      this.menu.open(this.sprite.position.x, this.sprite.position.y);
    }

    this.isMenuOpen = !this.isMenuOpen;
  }

  private setIdleAnimation(): void {
    if (this.movementInterval) clearInterval(this.movementInterval);
    clearInterval(this.animationInterval);

    this.idlingTexture.offset.setX(CharacterAnimations.IdlingCycles[this.direction][0] / CharacterAnimations.MovementIndexes);
    this.sprite.material.map = this.idlingTexture;

    let counter = 0;
    let tileIndexes: number[];

    switch (this.direction) {
      case Direction.Up:
        tileIndexes = CharacterAnimations.IdlingCycles.Up;
        break;
      case Direction.Down:
        tileIndexes = CharacterAnimations.IdlingCycles.Down;
        break;
      case Direction.Left:
        tileIndexes = CharacterAnimations.IdlingCycles.Left;
        break;
      case Direction.Right:
        tileIndexes = CharacterAnimations.IdlingCycles.Right;
        break;
    }

    this.animationInterval = setInterval(() => {
      this.idlingTexture.offset.setX(tileIndexes[counter] / CharacterAnimations.MovementIndexes);

      ++counter;
      if (counter >= tileIndexes.length) {
        counter = 0;
      }
    }, 250);

    if (this.idleTimer) clearInterval(this.idleTimer);
    this.idleTimer = setInterval(() => {
      ++this.idleDuration;

      if (this.idleDuration >= 30) {
        this.setReadingAnimation();
      }
    }, 1000)
  }

  private setReadingAnimation(): void {
    clearInterval(this.animationInterval);
    clearInterval(this.idleTimer);

    this.sprite.material.map = this.readingTexture;

    let intervalCounter = 0;
    this.animationInterval = setInterval(() => {
      this.readingTexture.offset.setX(CharacterAnimations.ReadingCycles[intervalCounter] / CharacterAnimations.ReadingIndexes);

      ++intervalCounter;
      if (intervalCounter >= CharacterAnimations.ReadingCycles.length) {
        intervalCounter = 0;
      }
    }, 200);
  }

  private setRunAnimation(resetAnimationIndex = true): void {
    clearInterval(this.animationInterval);
    clearInterval(this.idleTimer);
    this.idleDuration = 0;

    if (resetAnimationIndex) {
      this.currentRunningAnimationIndex = 0;
    }

    this.runningTexture.offset.setX(CharacterAnimations.RunningCycles[this.direction][0] / CharacterAnimations.MovementIndexes);
    this.sprite.material.map = this.runningTexture;

    let tileIndexes: number[];

    switch (this.direction) {
      case Direction.Up:
        tileIndexes = CharacterAnimations.RunningCycles.Up;
        break;
      case Direction.Down:
        tileIndexes = CharacterAnimations.RunningCycles.Down;
        break;
      case Direction.Left:
        tileIndexes = CharacterAnimations.RunningCycles.Left;
        break;
      case Direction.Right:
        tileIndexes = CharacterAnimations.RunningCycles.Right;
        break;
    }

    this.animationInterval = setInterval(() => {
      this.runningTexture.offset.setX(tileIndexes[this.currentRunningAnimationIndex] / CharacterAnimations.MovementIndexes);

      ++this.currentRunningAnimationIndex;
      if (this.currentRunningAnimationIndex >= tileIndexes.length) {
        this.currentRunningAnimationIndex = 0;
      }
    }, this.isSprintToggled ? 80 : 120);
  }

  private changeDirection(direction: Direction): void {
    switch (direction) {
      case Direction.Up:
        this.moveUp();
        break;
      case Direction.Down:
        this.moveDown();
        break;
      case Direction.Left:
        this.moveLeft();
        break;
      case Direction.Right:
        this.moveRight();
        break;
      default:
        return;
    }

    this.keyDownMap.set(direction, true);
  }

  private moveCollisionsY(y: number) {
    this.collisionPlane.moveY(y);
    this.interactionRange.moveY(y);
  }

  private moveCollisionsX(x: number) {
    this.collisionPlane.moveX(x);
    this.interactionRange.moveX(x);
  }

  private moveUp(): void {
    if (this.direction !== Direction.Up || !this.isMoving) {
      this.direction = Direction.Up;
      this.isMoving = true;

      clearInterval(this.movementInterval);
      this.setRunAnimation();
      this.movementInterval = setInterval(() => {
        const collision = this.detectCollision();

        if (!collision) {
          this.sprite.position.y += this.speed;
          this.moveCollisionsY(this.sprite.position.y);
          this.notifyPlayerYIndex();
        } else {
          const maxYPosition = collision.position.y - ((collision.geometry as PlaneGeometry).parameters.height / 2);
          const buffer = Math.abs(maxYPosition - this.sprite.position.y);
          console.log("Up: ", buffer);
          if (buffer < 1) { // this wont work if collision has < 1 height
            this.sprite.position.y = maxYPosition + .4999999;
          } else {
            // not sure what to do, kinda want to just do the this.sprite.position.y -= .1
          }
          this.moveCollisionsY(this.sprite.position.y);
          clearInterval(this.movementInterval);
        }

        this.camera.position.y = this.sprite.position.y;
      }, 20);
    }
  }

  private moveDown(): void {
    if (this.direction !== Direction.Down || !this.isMoving) {
      this.direction = Direction.Down;
      this.isMoving = true;

      clearInterval(this.movementInterval);
      this.setRunAnimation();
      this.movementInterval = setInterval(() => {
        const collision = this.detectCollision();

        if (!collision) {
          this.sprite.position.y -= this.speed;
          this.moveCollisionsY(this.sprite.position.y);
          this.notifyPlayerYIndex();
        } else {
          const minYPosition = collision.position.y + ((collision.geometry as PlaneGeometry).parameters.height / 2);
          const buffer = Math.abs(minYPosition - this.sprite.position.y);
          console.log("Down: ", buffer);
          if (buffer < 1) { // this wont work if collision has < 1 height
            this.sprite.position.y = minYPosition + 1.000000001;
          } else {
            // not sure what to do, kinda want to just do the this.sprite.position.y += .1
          }
          this.moveCollisionsY(this.sprite.position.y);
          clearInterval(this.movementInterval);
        }

        this.camera.position.y = this.sprite.position.y;
      }, 20);
    }
  }

  private moveRight(): void {
    if (this.direction !== Direction.Right || !this.isMoving) {
      this.direction = Direction.Right;
      this.isMoving = true;

      clearInterval(this.movementInterval);
      this.setRunAnimation();
      this.movementInterval = setInterval(() => {
        const collision = this.detectCollision();

        if (!collision) {
          this.sprite.position.x += this.speed;
          this.moveCollisionsX(this.sprite.position.x);
        } else {
          const maxXPosition = collision.position.x - ((collision.geometry as PlaneGeometry).parameters.width / 2);
          const buffer = Math.abs(maxXPosition - this.sprite.position.x);
          console.log("Right: ", buffer);
          if (buffer < 1) { // this wont work if collision has < 1 width
            this.sprite.position.x = maxXPosition - .500000001;
          } else {
            // not sure what to do, kinda want to just do the this.sprite.position.x -= .1
          }
          this.moveCollisionsX(this.sprite.position.x);
          clearInterval(this.movementInterval);
        }

        this.camera.position.x = this.sprite.position.x;
      }, 20);
    }
  }

  private moveLeft(): void {
    if (this.direction !== Direction.Left || !this.isMoving) {
      this.direction = Direction.Left;
      this.isMoving = true;

      clearInterval(this.movementInterval);
      this.setRunAnimation();
      this.movementInterval = setInterval(() => {
        const collision = this.detectCollision();

        if (!collision) {
          this.sprite.position.x -= this.speed;
          this.moveCollisionsX(this.sprite.position.x);
        } else {
          const minXPosition = collision.position.x + ((collision.geometry as PlaneGeometry).parameters.width / 2);
          const buffer = Math.abs(minXPosition - this.sprite.position.x);
          console.log("Left: ", buffer);
          if (buffer < 1) { // this wont work if collision has < 1 width
            this.sprite.position.x = minXPosition + .500000001;
          } else {
            // not sure what to do, kinda want to just do the this.sprite.position.x += .1
          }
          this.moveCollisionsX(this.sprite.position.x);
          clearInterval(this.movementInterval);
        }

        this.camera.position.x = this.sprite.position.x;
      }, 20);
    }
  }

  private notifyPlayerYIndex(): void {
    this.playerYIndex$.next(this.sprite.position.y);
  }

  private detectCollision(): Mesh | null {
    this.collisionPlane.mesh.userData.obb.copy(this.collisionPlane.mesh.geometry.userData.obb);
    this.collisionPlane.mesh.userData.obb.applyMatrix4(this.collisionPlane.mesh.matrixWorld);

    this.interactionRange.mesh.userData.obb.copy(this.interactionRange.mesh.geometry.userData.obb);
    this.interactionRange.mesh.userData.obb.applyMatrix4(this.interactionRange.mesh.matrixWorld);

    for (let i = 0; i < this.collisions.length; i++) {
      const collision = this.collisions[i].mesh;
      collision.userData.obb.copy(collision.geometry.userData.obb);
      collision.userData.obb.applyMatrix4(collision.matrixWorld);

      if (this.interactionRange.mesh.userData.obb.intersectsOBB(collision.userData.obb) && collision.userData.isInteractable) {
        console.log('something interactable in range');
        // enable ability to press "Space" to interact with the thing in range
      }

      if (this.collisionPlane.mesh.userData.obb.intersectsOBB(collision.userData.obb)) return collision;
    };

    return null;
  };
}
