import { ZPosition } from "core/enums/ZPosition";
import * as THREE from "three";
import { Scene } from 'three';

const InitializeScene = (scene: Scene) => {
  // Scene, Lighting
  scene.background = new THREE.Color(0x000000);
  scene.add(new THREE.AmbientLight(0xffffff, 1));

  // World Map
  var worldMapBaseGeometry = new THREE.PlaneGeometry(100, 100);
  var worldMapBaseTexture = new THREE.TextureLoader().load('./assets/map/world-map-base.png');
  worldMapBaseTexture.magFilter = THREE.NearestFilter;
  var worldMapBaseMaterial = new THREE.MeshBasicMaterial({ map: worldMapBaseTexture, transparent: true });
  var worldMapBase = new THREE.Mesh(worldMapBaseGeometry, worldMapBaseMaterial);
  worldMapBase.position.x = 0;
  worldMapBase.position.y = 50;
  worldMapBase.position.z = ZPosition.WorldMapBase;
  scene.add(worldMapBase);

  var worldMapStaticObjectsGeometry = new THREE.PlaneGeometry(100, 100);
  var worldMapStaticObjectsTexture = new THREE.TextureLoader().load('./assets/map/world-map-objects.png');
  worldMapStaticObjectsTexture.magFilter = THREE.NearestFilter;
  var worldMapStaticObjectsMaterial = new THREE.MeshBasicMaterial({ map: worldMapStaticObjectsTexture, transparent: true });
  var worldMapStaticObjects = new THREE.Mesh(worldMapStaticObjectsGeometry, worldMapStaticObjectsMaterial);
  worldMapStaticObjects.position.x = 0;
  worldMapStaticObjects.position.y = 50;
  worldMapStaticObjects.position.z = ZPosition.WorldMapStaticObject;
  scene.add(worldMapStaticObjects);

  var worldMapAboveGeometry = new THREE.PlaneGeometry(100, 100);
  var worldMapAboveTexture = new THREE.TextureLoader().load('./assets/map/world-map-above.png');
  worldMapAboveTexture.magFilter = THREE.NearestFilter;
  var worldMapAboveMaterial = new THREE.MeshBasicMaterial({ map: worldMapAboveTexture, transparent: true });
  var worldMapAbove = new THREE.Mesh(worldMapAboveGeometry, worldMapAboveMaterial);
  worldMapAbove.position.x = 0;
  worldMapAbove.position.y = 50;
  worldMapAbove.position.z = ZPosition.WorldMapAbove;
  scene.add(worldMapAbove);
}

export default InitializeScene;
