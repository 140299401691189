import * as THREE from "three";
import { Mesh, Scene } from "three";

export class Menu {
  plane: Mesh;

  constructor(private scene: Scene) {
    const texture = new THREE.TextureLoader().load(`./assets/map/controls.png`);
    const geometry = new THREE.PlaneGeometry(10, 10);
    const material = new THREE.MeshBasicMaterial({ map: texture, transparent: true });

    this.plane = new THREE.Mesh(geometry, material);
  }

  open(x: number, y: number): void {
    this.plane.position.set(x, y, 1);
    this.scene.add(this.plane);
  }

  close(): void {
    this.scene.remove(this.plane);
  }
}
